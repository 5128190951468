




































import Vue from 'vue';
import { Component, Emit, Prop, Ref, Watch } from 'vue-property-decorator';
import GcButton from './GcButton.vue';
import Icon from './Icon.vue';
import Cookies from 'js-cookie';

@Component({
    components: {
        GcButton,
        Icon
    }
})
export default class ContentOverlay extends Vue {
    @Prop({ default: false, type: Boolean }) value: boolean;
    @Prop({ default: false, type: Boolean }) showOnLoad: boolean;
    @Prop({ default: false, type: Boolean }) narrow: boolean;
    @Prop({ default: 500 }) delay: number;
    @Prop() buttonLabel: string;

    @Ref() parentContainer: HTMLDivElement;
    @Ref() contentContainer: HTMLDivElement;

    scrollable = false;

    visible = false;

    mounted(): void {
        const alreadyShown = !!Cookies.get('gc-overlay-shown');
        if (this.showOnLoad && !alreadyShown) {
            setTimeout(() => this.show(), this.delay);
        }
    }

    protected checkIfScrollable(): void {
        this.scrollable = !!this.parentContainer && !!this.contentContainer &&
            (this.parentContainer.clientHeight < this.contentContainer.clientHeight);
    }

    @Emit('cta')
    protected ctaClick(): void {
        this.hide();
    }

    @Emit('input')
    @Emit('hide')
    protected hide(): boolean {
        document.body.style.overflow = '';
        this.visible = false;
        window.removeEventListener('resize', this.checkIfScrollable);
        return false;
    }

    @Emit('input')
    @Emit('show')
    protected show(): boolean {
        document.body.style.overflow = 'hidden';
        this.visible = true;
        Cookies.set('gc-overlay-shown', 'true');
        window.addEventListener('resize', this.checkIfScrollable);
        this.$nextTick(() => this.checkIfScrollable());
        return true;
    }

    @Watch('value')
    watchValue(newVal: boolean): void {
        if (newVal) {
            this.show();
        } else {
            this.hide();
        }
    }
}


import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop, Provide, Ref } from 'vue-property-decorator';
import Swiper, { EffectFade, SwiperOptions } from 'swiper';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config.js';
import { TailwindConfig } from 'tailwindcss/tailwind-config';
import GcSwiperSlide from './GcSwiperSlide.vue';

Swiper.use([EffectFade]);

@Component
export default class GcSwiper extends Vue {
    @Ref('container') container;

    @Prop({ default: 'horizontal' }) direction!: 'horizontal' | 'vertical';
    @Prop({ type: Boolean, default: false }) contrast: boolean;
    @Prop({ type: Boolean, default: false }) loop!: boolean;
    @Prop({ default: 0 }) gap: number | { [breakpoint: string]: number };
    @Prop({ default: '' }) containerClasses: string;
    @Prop({ default: '' }) slideClasses: string;
    @Prop({ default: 0 }) start!: number;
    @Prop({ default: 300 }) speed!: number;
    @Prop({ default: 0 }) slidesOffsetAfter!: number | { [breakpoint: string]: number };
    @Prop({ type: Boolean, default: false }) showThumbs: boolean;
    @Prop({ type: Boolean, default: false }) fullHeightSlides: boolean;
    @Prop({ type: Boolean, default: false }) static: boolean;
    @Prop({ type: Boolean, default: false }) editMode: boolean;
    @Prop({ default: 'auto' }) slidesPerView: number | 'auto' | { [breakpoint: string]: number | 'auto' };
    @Prop({ default: true }) hideOverflow: boolean;
    @Provide('registerSlide') childRegistration = this.register;

    slides: InstanceType<typeof GcSwiperSlide>[] = [];
    swiper = null;
    index = this.start;
    options: SwiperOptions = {
        watchOverflow: true,
        observer: true,
        loop: this.loop,
        speed: this.speed,
        direction: this.direction,
        touchReleaseOnEdges: true,
        allowSlidePrev: false,
        initialSlide: this.start,
        observeParents: true,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        shortSwipes: false,
        longSwipesRatio: 0.1,
        longSwipesMs: 100,
        noSwipingSelector: 'video',
        effect: this.direction === 'vertical' ? 'fade' : 'slide',
        fadeEffect: {
            crossFade: true
        }
    };

    twConfig: TailwindConfig = resolveConfig(tailwindConfig);

    observing = false;
    prevVisible = false;
    nextVisible = false;
    isPlaying = false;

    mounted(): void {
        if (this.editMode) {
            return;
        }
        this.setBreakpointOption(this.direction, 'direction');
        this.setBreakpointOption(this.gap, 'spaceBetween');
        this.setBreakpointOption(this.slidesPerView, 'slidesPerView');
        this.setBreakpointOption(this.slidesOffsetAfter, 'slidesOffsetAfter');
        this.swiper = new Swiper(this.container, this.options);
        this.swiper.on('activeIndexChange', this.indexChanged);
        this.observeSlideVisibility();
    }

    updated(): void {
        if (!this.observing) {
            this.observeSlideVisibility();
        }
    }

    indexChanged({ activeIndex, realIndex }): void {
        this.index = this.loop ? realIndex : activeIndex;
    }

    next(): void {
        if (this.swiper) {
            if (this.loop) {
                this.index = this.index + 1 >= this.slides.length ? 0 : this.index + 1;
            } else if (this.index + 1 < this.slides.length) {
                this.index++;
            }
            this.swiper.slideNext();
        }
    }

    prev(): void {
        if (this.swiper) {
            if (this.loop) {
                this.index = this.index > 0 ? this.index - 1 : this.slides.length - 1;
            } else if (this.index > 0) {
                this.index--;
            }
            this.swiper.slidePrev();
        }
    }

    goTo(index): void {
        if (this.swiper) {
            this.swiper.slideTo(index);
            this.index = index;
        }
    }

    render(h: CreateElement): VNode {
        return h('div', { staticClass: 'w-full' }, [
            h('div', {
                staticClass: `swiper-container w-full ${this.additionalContainerClasses}`,
                ref: 'container'
            }, [
                h('div', {
                    staticClass: `swiper-wrapper flex flex-nowrap ${this.wrapperClasses}`
                }, this.$slots.default ? this.$slots.default.filter(x => x.tag).map((el, i) => h('div', {
                    staticClass: `swiper-slide flex-shrink-0 max-w-full ${this.slideClasses}${this.index === i ? ' slide-active' : ''}`
                }, [el])) : [])
            ]),
            (this.showThumbs && this.slides.length) ? h('gft-swiper-thumbs', {
                props: {
                    slides: this.slides.map(({ kicker, title, text, image }) => ({ kicker, title, text, image })),
                    index: this.index,
                    contrast: this.contrast
                },
                on: {
                    thumb: this.goTo
                }
            }) : null,
            (this.$scopedSlots.pagination && !this.editMode) ? this.$scopedSlots.pagination({
                index: this.index + 1,
                total: this.numSlides,
                teaser: this.currentSlide,
                go: this.goTo,
                isPlaying: this.isPlaying
            }) : null,
            (this.$scopedSlots.controls) ? this.$scopedSlots.controls({
                prev: this.prev,
                next: this.next,
                index: this.index,
                show: this.prevVisible || this.nextVisible,
                prevVisible: this.prevVisible,
                nextVisible: this.nextVisible,
                isPlaying: this.isPlaying
            }) : null
        ]);
    }

    // called by child slides
    register(slide: InstanceType<typeof GcSwiperSlide>): void {
        slide.$on('click', e => {
            const index = this.slides.findIndex(x => x._id === e._id);
            if (index >= 0 && index < this.slides.length) {
                this.goTo(index);
            }
        });
        this.slides.push(slide);
        if (this.$children.filter(this.filterSlides).length === this.slides.length) {
            // do init
        }
    }

    breakpointSize(name: string): number {
        if (Object.prototype.hasOwnProperty.call(this.twConfig.theme.screens, name)) {
            return parseInt(this.twConfig.theme.screens[name]);
        }
        return -1;
    }

    setBreakpointOption(value: string | number | object, name: string): void {
        const breakpoints = this.options.breakpoints || {};
        if (typeof value === 'number' || typeof value === 'string') {
            this.options[name] = value;
            return;
        }
        Object.entries(value).forEach(x => {
            const bp = this.breakpointSize(x[0]);
            if (bp > 0) {
                if (!Object.prototype.hasOwnProperty.call(breakpoints, bp)) {
                    breakpoints[bp] = {};
                }
                breakpoints[bp][name] = x[1];
            } else {
                this.options[name] = x[1];
            }
        });
        if (!this.options.breakpoints) {
            this.options.breakpoints = breakpoints;
        }
    }

    // check children to only count slide components
    filterSlides(child: Vue): boolean {
        return child.$vnode && child.$vnode.componentOptions && child.$vnode.componentOptions.tag === 'gft-swiper-slide';
    }

    get numSlides(): number {
        if (this.$slots.default) {
            return this.$slots.default.filter(x => x.tag).length;
        }
        return 0;
    }

    get currentSlide(): InstanceType<typeof GcSwiperSlide> {
        const index = (this.index - this.start) < 0 ? this.slides.length - 1 : this.index - this.start;
        if (this.slides.length && this.slides.length > index) {
            return this.slides[index];
        }
        return null;
    }

    get additionalContainerClasses(): string {
        const classes = [this.containerClasses];
        if (this.hideOverflow) {
            classes.push('overflow-hidden');
        }
        return classes.filter(x => x.length).join(' ');
    }

    get wrapperClasses(): string {
        if (typeof this.direction === 'string') {
            return this.direction === 'vertical' ? 'flex-col w-full h-full' : 'flex-row';
        }
        // md:flex-col lg:flex-col xl:flex-col md:flex-row lg:flex-row xl:flex-row
        const classes = [];
        Object.entries(this.direction).forEach(x => {
            const bp = this.breakpointSize(x[0]);
            const dir = x[1] === 'vertical' ? 'flex-col' : 'flex-row';
            if (bp > 0) {
                classes.push(`${x[0]}:${dir}`);
            } else {
                classes.push(dir);
            }
        });
        return classes.join(' ');
    }

    observeSlideVisibility(): void {
        if (this.slides && this.slides.length > 0) {
            this.slides
                .map(slide => slide.$el.parentElement)
                .forEach(el => {
                    const options: IntersectionObserverInit = {
                        threshold: 0.95,
                        root: this.$el.querySelector('.swiper-container')
                    };
                    // eslint-disable-next-line no-undef
                    const observer = new IntersectionObserver(entries => {
                        const isLeft = entries[0].target.isSameNode(this.slides[0].$el.parentElement);
                        const isRight = entries[0].target.isSameNode(this.slides[this.slides.length - 1].$el.parentElement);
                        if (isLeft) this.prevVisible = !entries[0].isIntersecting;
                        if (isRight) this.nextVisible = !entries[0].isIntersecting;
                    }, options);
                    observer.observe(el);
                });
            this.observing = true;
        }
    }
}
